import GeographicLib from 'geographiclib';

import { GET_LOCAL_POSITION, GET_TRAFFIC } from '../GraphQL/Waffle/Queries';
import { Logger } from './Logger';
import { ApolloClient } from '@apollo/client';

const geodesic = GeographicLib.Geodesic.WGS84;

const getLocationTraffic = async (client: ApolloClient<object>, fn: any) => {
  Logger('TrafficService.getTrafficLocation: starting');

  const {
    data: { position },
  } = await client.query({ query: GET_LOCAL_POSITION });
  const distance = 1.5; // Show things within 1.5 miles (for now)
  const deg = distance / 69.2;

  const key = 'qZjAfqEuEAsVjKNh5SHxKQVRIMOzoOgI';
  const lx = (position.longitude - deg).toFixed(2);
  const ly = (position.latitude - deg).toFixed(2);
  const rx = (position.longitude + deg).toFixed(2);
  const ry = (position.latitude + deg).toFixed(2);

  const url = `https://api.tomtom.com/traffic/services/4/incidentDetails/s3/${ly},${lx},${ry},${rx}/15/-1/json?projection=EPSG4326&key=${key}`;

  const response = await fetch(url);
  const json = await response.json();

  const tempResults = json.tm.poi.map((incident: any) => {
    const distance =
      (geodesic.Inverse(
        position.latitude,
        position.longitude,
        incident.p.y,
        incident.p.x
      ).s12 *
        3.2808) /
      5280;
    return [
      distance,
      `${incident.d}: ${incident.f} at ${incident.t} approx ${distance.toFixed(
        2
      )} miles away`,
    ];
  });

  const results = tempResults.sort((a: any, b: any) => a[0] - b[0]);

  // console.log(`TrafficService.getTrafficLocation: Results: ${JSON.stringify(results)}`);

  await fn(results);

  // console.log(`TrafficService: traffic: ${JSON.stringify(json.tm.poi)}`);
};

const cacheLocationTraffic = async (client: ApolloClient<object>) => {
  await getLocationTraffic(client, async (t: any) => {
    Logger(`TrafficService.cacheLocationTraffic: ${JSON.stringify(t)}`);

    const nearest = t.length >= 1 ? t[0][1] : '';

    const traffic = {
      __typename: 'Traffic',
      num_incidents: t.length,
      nearest,
    };

    await client.writeQuery({
      query: GET_TRAFFIC,
      data: { traffic },
    });
  });
};

export default { cacheLocationTraffic, getLocationTraffic };
