// import * as Sentry from '@sentry/react-native';
import {
  PolicyCoverage,
  Policies,
  Policy,
  Product,
  Products,
} from '../../component-config';
import {
  ProductCyber,
  ProductHome,
  ProductLife,
  ProductPet,
} from '../Constants/Products';
import { GetFinalizedUserPolicies_finalizedUserPolicies } from '../../../operation-result-types';

export const POLICY_STATUS_UNSPECIFIED = 0;
export const POLICY_STATUS_DRAFT = 4; // creating, but not priced
export const POLICY_STATUS_PENDING = 'PENDING'; // pricing?
export const POLICY_STATUS_PAID = 2;
export const POLICY_STATUS_EXTERNAL_PENDING = 'EXTERNAL_PENDING'; // waiting for external
export const POLICY_STATUS_CANCELLED = 5;
export const POLICY_STATUS_FINALIZED = 'FINALIZED';

export const POLICY_TYPE_UNSPECIFIED = 0;
export const POLICY_TYPE_MONTHLY = 'MONTHLY';
export const POLICY_TYPE_YEARLY = 'YEARLY';
export type PolicyFrequency = 'MONTHLY' | 'YEARLY';
export const POLICY_TYPE_ONCE = 3;

export const COVERAGE_SELECTION_TYPE_CHOICE = 'CHOICE';

export const COVERAGE_TRAVEL_CFAR = 'CFAR';

export const MapTrue = (l: any) => {
  if (typeof l !== 'undefined') {
    return Object.keys(l).filter((k) => l[k]);
  } else {
    return [];
  }
};

export const CountMapTrue = (l: any) => MapTrue(l).length;

export const MergeSelectedAddOns = (addOns: any, selectedStatus: any) => {
  return addOns?.map((a: any) => {
    const matched = selectedStatus?.filter((s: any) => s.id === a.id);
    // Logger(`matched status: ${JSON.stringify(matched)}`)
    const selected = matched?.[0]?.selected ?? a.selected;
    return { ...a, selected };
  });
};

// This is used in product chooser to determine if customer can select
// a product.
// export const HasProductPolicy = (user: any, product: any, policies: any) =>
//   user?.userState === 'COMPLETE' && // xxx : is this necessary anymore?
//   !product.multiple &&
//   policies.some((policy: any) => product.id === policy.product_id);
export const IsLife = (item: Product): boolean => item.id === ProductLife;
export const IsLifePolicy = (item: Policy): boolean =>
  item.productId === 'Life';

export const IsPet = (item: Product): boolean => item.id === ProductPet;

export const IsPetPolicy = (item: Policy): boolean =>
  item.productId === ProductPet;

export const IsHomePolicy = (item: Policy): boolean =>
  item?.productId === ProductHome;

export const IsTravelPolicy = (item: Policy): boolean =>
  item.productId === 'Travel';

export const IsCyber = (item: Product): boolean => item.id === ProductCyber;

export const IsCyberPolicy = (item: Policy): boolean =>
  item.productId === 'Cyber';

export const IsRentalPolicy = (item: Policy): boolean =>
  item.productId === 'Renters';

export const IsTotalPolicy = (item: Policy): boolean =>
  item.productId === 'Total';

export const IsMonthly = (item: Policy): boolean =>
  item.policyType === POLICY_TYPE_MONTHLY;

export const PolicyShortFrequencyLabel = (item: Policy): string => {
  switch (item.policyType) {
    case POLICY_TYPE_MONTHLY:
      return '/mo';
    case POLICY_TYPE_YEARLY:
      return '/yr';
    default:
      return '';
  }
};

export const PolicyShortFrequencyLabelRaw = (
  policyFrequency: PolicyFrequency
): string => {
  switch (policyFrequency) {
    case POLICY_TYPE_MONTHLY:
      return '/mo';
    case POLICY_TYPE_YEARLY:
      return '/yr';
    default:
      return '';
  }
};

export const PolicyMidFrequencyLabel = (item: Policy): string => {
  switch (item.policyType) {
    case POLICY_TYPE_MONTHLY:
      return '/month';
    case POLICY_TYPE_YEARLY:
      return '/year';
    default:
      return '';
  }
};

export const PolicyUniqueFrequency = (policies: Policies): string => {
  if (!!policies && policies.length > 0) {
    // Logger(`PolicyUniqueFrequency: ${JSON.stringify(policies)}`);
    const uniqueTypes = Array.from(new Set(policies.map((_) => _.policyType)));

    if (uniqueTypes.length === 1) {
      switch (uniqueTypes[0]) {
        case POLICY_TYPE_MONTHLY:
          return '/mo';
        case POLICY_TYPE_YEARLY:
          return '/yr';
        default:
          return '';
      }
    }
  }

  return '';
};

export const PolicyLongFrequencyWord = (item: Policy): string => {
  switch (item.policyType) {
    case POLICY_TYPE_MONTHLY:
      return 'Monthly';
    case POLICY_TYPE_YEARLY:
      return 'Annual';
    default:
      return '';
  }
};
export const PolicyLongFrequencyLabel = (item: Policy): string => {
  switch (item.policyType) {
    case POLICY_TYPE_MONTHLY:
      return 'per month';
    case POLICY_TYPE_YEARLY:
      return 'per year';
    default:
      return '';
  }
};

export const HasOnceBilling = (policies: Policies): boolean =>
  policies.some((item) => !IsMonthly(item));

// export const HasLifePolicy = (policies: Policies): boolean =>
//   policies.some((item) => IsLifePolicy(item));

export const HasPetPolicy = (policies: Policies): boolean =>
  policies.some((item) => IsPetPolicy(item));

export const HasTravelPolicy = (policies: Policies): boolean =>
  policies.some((item) => IsTravelPolicy(item));

export const HasNonBillablePolicy = (policies: Policies): boolean =>
  policies.some((item) => IsCyberPolicy(item) || IsPetPolicy(item));

export const HasBillablePolicy = (policies: Policies): boolean =>
  policies.some((item) => !IsCyberPolicy(item) && !IsPetPolicy(item));

export const HasConsentPolicy = (policies: Policies): boolean =>
  policies.some((item) => IsPetPolicy(item) || IsCyberPolicy(item));

// export const HasExternalPrePurposeDisclosures = (policies: DraftUserPolicies_draftUserPolicies[]): boolean =>
//   policies.some((item) => IsCyberPolicy(item));

export const HasCyberPolicy = (policies: Policies): boolean =>
  policies.some((item) => IsCyberPolicy(item));

export const HasHomePolicy = (policies: Policies) =>
  policies.some((item) => IsHomePolicy(item));

export const HasLifePolicy = (policies: Policies) =>
  policies.some(IsLifePolicy);

export const HasRentersPolicy = (policies: Policies) =>
  policies.some(IsRentalPolicy);

export const TravelPolicyCFARValue = (policy: Policy) =>
  policy?.coverage?.filter(
    (c: PolicyCoverage) => c.productCoverageId === COVERAGE_TRAVEL_CFAR
  )?.[0]?.coverageLimit;

export const HasRentersLiabilityOnly = (policy: Policy) =>
  IsRentalPolicy(policy) &&
  policy?.coverage?.some(
    (c) =>
      c.productCoverageId === 'Product Type' &&
      c.coverageLimit === 'Liability Only'
  );

export const HasRentersContentAndLiability = (policy: Policy) =>
  IsRentalPolicy(policy) &&
  policy?.coverage?.some(
    (c) =>
      c.productCoverageId === 'Product Type' &&
      c.coverageLimit === 'Liability and Contents'
  );

// export const OnlyLifePolicies = (policies: Policies) =>
//   HasLifePolicy(policies) && policies.length === 1;

export const OnlyPetPolicies = (policies: Policies) =>
  HasPetPolicy(policies) && policies.length === 1;

export const OnlyRentersPolicies = (policies: Policies) =>
  HasRentersPolicy(policies) && policies.length === 1;

export const IsCyberOnly = (products: Products) =>
  products.length === 1 && IsCyber(products[0]);

export const IsLifeOnly = (products: Products) =>
  products.length === 1 && IsLife(products[0]);

export const WaffleBillablePolicies = (policies: Policies) =>
  policies.some((item: any) => !IsPetPolicy(item));

export const TotalPolicyPrice = (policy: Policy) => policy.price;

export const TotalPolicyPrices = (policies: Policies) =>
  policies
    .filter((item) => !IsTotalPolicy(item))
    .map((item) => TotalPolicyPrice(item))
    .reduce((acc, item) => acc + item, 0)
    .toFixed(2);

export const CoverageFilter = (
  policy: Policy,
  coverage: PolicyCoverage
): boolean => {
  // If it's Renters policy, and Liability only, then exclude coverage items
  if (HasRentersLiabilityOnly(policy)) {
    return (
      !coverage.productCoverageId.startsWith('Content ') &&
      !coverage.productCoverageId.startsWith('Incremental Coverage ')
    );
  }

  return true;
};

export const PolicyNormalize = (
  policy: GetFinalizedUserPolicies_finalizedUserPolicies
): GetFinalizedUserPolicies_finalizedUserPolicies => {
  // If it's Renters policy, and Liability only, make sure add ons are all turned off!
  if (HasRentersLiabilityOnly(policy)) {
    return {
      ...policy,
      addOns: policy.addOns.map((a) => ({ ...a, selected: false })),
    };
  }

  return policy;
};
