import Config from 'react-native-config'; // todo : make dynamic
import { Platform } from 'react-native';

export default () => {
  if (Platform.OS === 'web') {
    return {
      media: process.env.V1_API_URL,
      api: `${process.env.V1_API_URL}/graphql`,
      v2: process.env.V2_API_URL,
    };
  } else {
    return {
      media: process.env.V1_API_URL,
      api: `${Config.API_URL}/graphql`,
    };
  }
};
