export function hasLifeSaver() {
  return false;
}

export function hasGeo() {
  return false;
}

export function getStripePublishableKey() {
  return '';
}

export function getStripeMerchantId() {
  return '';
}

export function getAspcaBaseUrl() {
  return process.env.ASPCA_BASE_URL;
}

export function getAspcaBridgeUrl() {
  return process.env.ASPCA_BRIDGE_URL;
}

export function getAspcaApiKey() {
  return '';
}

export function enableSentry() {
  return process.env.ENABLE_SENTRY === 'true';
}

export function getBuildNumber() {
  // We pin to a specific version for web.
  return 300;
}

export const hasBottomSwitcher = () => false;
