import {
  CREATE_SETUP_INTENT,
  GET_CUSTOMER_PAYMENT_METHODS,
} from '../GraphQL/Waffle/Queries';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { ApolloClient } from '@apollo/client';
import {
  ListCustomerPaymentMethods,
  LocalUser_user,
} from '../../../operation-result-types';
import { UserName } from './UserHelpers';
import { Logger } from './Logger';
import { getCircularReplacer } from './MiscFunctions';

export const saveCardToStripe = async (
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  elements: any,
  stripe: any,
  user: LocalUser_user,
  zipCode: string
) => {
  // Get a reference to a mounted CardElement. Elements knows how
  // to find your CardElement because there can only ever be one of
  // each type of element.
  const cardElement = elements.getElement(CardNumberElement);

  const {
    data: { createSetupIntent },
  } = await client.mutate({ mutation: CREATE_SETUP_INTENT });

  Logger(
    `confirmCardSetup: name=${JSON.stringify(
      UserName(user)
    )} zipCode=${JSON.stringify(zipCode)} setupIntent=${JSON.stringify(
      createSetupIntent,
      getCircularReplacer()
    )} element=${JSON.stringify(!!cardElement, getCircularReplacer())}`
  );

  const { clientSecret } = createSetupIntent;

  const response = await stripe.confirmCardSetup(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: UserName(user),
        address: {
          postal_code: zipCode,
        },
        // other properties here?
      },
    },
  });

  Logger(
    `confirmCardSetup: ${JSON.stringify(response, getCircularReplacer())}`
  );

  if (response.error) {
    return response.error;
  } else {
    const {
      setupIntent: { payment_method },
    } = response;

    // get cards, filter payment method, and return as paymentMethod?

    const { data: paymentMethods } =
      await client.query<ListCustomerPaymentMethods>({
        query: GET_CUSTOMER_PAYMENT_METHODS,
        fetchPolicy: 'network-only',
      });

    const paymentMethod = paymentMethods?.listCustomerPaymentMethods?.find(
      (pm) => pm.id === payment_method
    );

    return {
      code: undefined,
      message: undefined,
      payment_method,
      paymentMethod,
    };
  }
};
