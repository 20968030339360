/* eslint-disable @typescript-eslint/ban-types */
import Geolocation, { GeoPosition } from 'react-native-geolocation-service';
import { Logger } from './Logger';
import TrafficService from './TrafficService';
import { Platform } from 'react-native';
import { GET_LOCAL_POSITION } from '../GraphQL/Waffle/Queries';
import { ApolloClient } from '@apollo/client';

let _id: any;

const GeoOptions = {
  enableHighAccuracy: true,
  timeout: 500,
  maximumAge: 10000,
  useSignificantChanges: true,
};

const positionUpdated = async (client: ApolloClient<object>) => {
  // Weather and Traffic require coordinates, so we run it here.
  //  todo : change this to run as-needed periodically so we always
  //    have fresh weather and traffic!
  await TrafficService.cacheLocationTraffic(client);
  // await WeatherService.startService(client);
};

const updatePosition = async (client: ApolloClient<object>, p: GeoPosition) => {
  Logger(`GeoService updatePosition: coords=${JSON.stringify(p.coords)}`);

  await client.writeQuery({
    query: GET_LOCAL_POSITION,
    data: {
      position: {
        __typename: 'Coords',
        latitude: p.coords.latitude,
        longitude: p.coords.longitude,
      },
    },
  });
};

const startService = (client: ApolloClient<object>) => {
  Logger('GeoService starting');

  Geolocation.setRNConfiguration({
    skipPermissionRequests: false,
    authorizationLevel: 'whenInUse',
  });
  Geolocation.requestAuthorization();

  _id = Geolocation.watchPosition(
    async (p) => {
      await updatePosition(client, p);

      await positionUpdated(client);

      Logger('GeoService watchPosition done');
    },
    (error) =>
      Logger(`GeoService watchPosition error: ${JSON.stringify(error)}`),
    GeoOptions
  );

  // const msg = `GeoService heartbeat: id=${_id}`;
  //
  // setInterval(() => {
  //   client
  //     .query({ query: GET_LOCAL_POSITION })
  //     .then(data => Logger(`${msg} currentId=${_id} data=${JSON.stringify(data)}`));
  // }, 10000);

  Logger('GeoService initialized');
};

export const forceUpdate = async (client: ApolloClient<object>) => {
  if (Platform.OS === 'ios') {
    Logger(`GeoService force update`);

    await startServiceIfNecessary(client);

    await Geolocation.getCurrentPosition(
      (p) => updatePosition(client, p),
      (error) =>
        Logger(
          `GeoService forceUpdate error ${error.code} message=${error.message}`
        ),
      {
        timeout: 2000,
        enableHighAccuracy: true,
        maximumAge: 10000,
      }
    );
  }
};

const stopService = () => {
  Logger('GeoService stopping');
  _id && Geolocation.clearWatch(_id);
  _id = null;
};

const startServiceIfNecessary = async (client: ApolloClient<object>) => {
  if (!isInitialized()) {
    await startService(client);
  } else {
    Logger(`GeoService already started: ${_id} ${isInitialized()}`);
  }
};

const isInitialized = () => !!_id;

export default {
  startService,
  stopService,
  isInitialized,
  startServiceIfNecessary,
  forceUpdate,
};
