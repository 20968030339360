import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import { Logger } from './Logger';
import { GET_CURRENT_USER } from '../GraphQL/Waffle/Queries';
import { CacheUser } from './UserHelpers';
import { GetCurrentUser } from '../../../operation-result-types';
import { ApolloClient } from '@apollo/client';
import { cachedTokenReactiveVar } from '../GraphQL/Waffle/ApolloClientBuilder';

// export interface ICognitoSignIn {
//   user?: any;
//   code?: string;
//   name?: string;
//   message?: string;
// }

export interface ISignUpError {
  code: string;
  name?: string;
  message?: string;
}

export const CognitoSignUp = async (
  email: string,
  password: string,
  attributes?: Record<string, unknown>
): Promise<ISignUpResult> => {
  try {
    const result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        ...attributes,
      },
    });
    // Logger(`user: ${JSON.stringify(result.user)} userSub: ${JSON.stringify(result.userSub)} userConfirmed: ${JSON.stringify(result.userConfirmed)}`);
    return result;
  } catch (error) {
    Logger(`error signing up: ${JSON.stringify(error)}`);
    throw new Error(error.message);
  }
};

// export async function CognitoSignIn(
//   email: string,
//   password: string
// ): Promise<ICognitoSignIn> {
//   try {
//     const user = await Auth.signIn(email, password);
//
//     // Logger(`DEBUG: signed in user: ${JSON.stringify(user)}`)
//
//     return { user };
//   } catch (error) {
//     Logger(`error signing in: ${JSON.stringify(error)}`);
//     if (error?.code) {
//       return { code: error.code, name: error?.name, message: error?.message };
//     }
//     return null;
//   }
// }

export async function CognitoSignOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    Logger(`error signing out: ${JSON.stringify(error)}`);
  }
}

export const RestoreCognitoUserState = async (client: ApolloClient<object>) => {
  // Similar to RestoreUserState, but uses Cognito
  // 1. check AsyncStorage.   If there is a taken, grab it.
  // 2. <strike>Verify token is valid.</strike>
  // 3. Fetch user information from server, and store in Apollo local  DONE
  // 4. Based on user state, go to that specific screen.

  try {
    const data = await Auth.currentSession();

    if (data?.isValid()) {
      const token = data.getIdToken().getJwtToken();
      cachedTokenReactiveVar(token);

      // Logger(`DEBUG cognito valid: ${data.isValid()} token ${token}`);
      //Logger(`cognito startup: ${JSON.stringify(data)}`);
      // We have a token, so let's try to fetch the user's information from the server.
      // We pull it from the network because otherwise user state might be
      // stale depending on when it was rehydrated from local storage.
      const {
        data: { currentUser },
      } = await client.query<GetCurrentUser>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      });

      // If we don't get back a valid user, we need to bail.  We should verify
      // that we didn't error or timeout from the backend, but if we can't restore
      // the user, we should assume there's a problem with the token, and clear
      // out the user.
      if (!currentUser || !currentUser.id) {
        Logger(
          `RestoreCognitoUserState: token ${JSON.stringify(
            token
          )} suspect user: ${JSON.stringify(currentUser)}  Clearing login`
        );

        return {};
      }

      await CacheUser(client, currentUser);

      return { token, user: currentUser, userId: currentUser.id }; // this comes from database, not AWS
    }
  } catch (e) {
    // todo : this could throw a graphQL error...
    Logger(`error restoring user: ${JSON.stringify(e)} code=${e.code}`);
    // if (e !== 'No current user') {
    //   throw new Error(e?.message);  // rethrow.  Is that ok to do it here?
    // }
  }

  // await ClearUserState(client);

  return {};
};
