import { Logger } from './Logger';
import { GET_PET_POLICY_QUOTE_ID } from '../GraphQL/Waffle/Queries';
import { cloneObj } from './MiscFunctions';
import { getAspcaApiKey, getAspcaBaseUrl } from './FeatureFlags';
import { ApolloClient } from '@apollo/client';

const MONTH_NAMES = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const sentryOptions = {
  category: 'payment',
  // level: Sentry.Severity.Info,
};

const normalizeCardType = (type: any) => {
  if (type === 'amex') {
    return 'AmericanExpress';
  } else {
    return type;
  }
};

export const HandleAspcaPayment = async (
  // native only
  client: ApolloClient<object>,
  cardInfo: any,
  paymentMethod: any,
  paperless: any,
  user: any,
  setError: any
) => {
  Logger(`handleASPCA: starting`);

  // todo : only get draft pet policy
  const {
    data: { getPetPolicy },
  } = await client.query({
    query: GET_PET_POLICY_QUOTE_ID,
    fetchPolicy: 'network-only',
  });

  const baseUrl = getAspcaBaseUrl();
  const url = `${baseUrl}/quoting/quotes/${getPetPolicy.externalQuoteId}`;

  Logger(
    `handleASPCA: fetching quote ${JSON.stringify(
      getPetPolicy?.externalQuoteId
    )}`,
    true,
    sentryOptions
  );

  const response = await fetch(url);
  const js = await response.json();
  const {
    name,
    card: { cvc, expMonth, number },
  } = cardInfo;
  const {
    card: { expYear, brand, last4 },
  } = paymentMethod;

  // Build response for update
  const billingInfo = {
    billingType: 'CreditCard',
    billingFrequency: 'Monthly',
    creditCardType: normalizeCardType(brand),
    nameOnAccount: name,
    expirationDate: {
      month: MONTH_NAMES[expMonth],
      year: expYear,
    },
    accountNumber: number,
  };

  js.billingInformation = billingInfo;
  js.goPaperless = paperless;

  // redact billingInfo.accountNumber!
  const bi = cloneObj(billingInfo);
  if (bi?.accountNumber) {
    bi.accountNumber = 'XXXXXXXX';
  }

  Logger(
    `handleASPCA: updating billing information: ${JSON.stringify(bi)}`,
    true,
    sentryOptions
  );
  const postResponse = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-ApiKey': getAspcaApiKey(),
    },
    body: JSON.stringify(js),
  });
  const postJs = await postResponse.json();

  Logger(
    `handleASPCA: update billing information response: ${JSON.stringify(
      postJs
    )} Status: ${postResponse.status}`
  );

  ////// todo : from here through updating pet account number could be pushed into finalization on backend
  //            but we still need last4 so creation of member portal account works

  Logger(`handleASPCA: finalizing quote`, true, sentryOptions);

  const finalizeResponse = await fetch(url + '/finalize', {
    method: 'POST',
  });
  const finalizeJs = await finalizeResponse.json();

  // note: we should have a more robust way of dealing with detecting failures!
  if (finalizeResponse.status === 500 || finalizeResponse.status === 400) {
    Logger(
      `handleASPCA: error finalizing quote. Error: "${finalizeJs}" Error status: ${finalizeResponse.status}`
    );
    Logger(`handleASPCA: quote: ${JSON.stringify(js)}`);
    setError(finalizeJs);
    return {};
  }
  Logger(
    `finalize json response: ${JSON.stringify(
      finalizeResponse
    )} ${JSON.stringify(finalizeJs)}`
  ); // clean up

  const { accountNumber } = finalizeJs;

  Logger(
    `handleASPCA: account number: ${accountNumber} ${user.email}`,
    true,
    sentryOptions
  );

  Logger(`handleASPCA: done`, true, sentryOptions);

  return { last4, accountNumber };
};

export const CreateAspcaMemberAccount = async (
  // native only
  user: any,
  password: string,
  accountNumber: string,
  last4: string
) => {
  // Register ASPCA account
  const baseUrl = getAspcaBaseUrl();
  const registerUrl = `${baseUrl}/quoting/register`;
  const registerBody = {
    logonName: user.email,
    password,
    accountNumber: accountNumber,
    zipcode: user.zip,
    last4DigitsOnAccountInfo: last4,
    sentFrom: 'WaffleApp',
  };

  Logger(`handleASPCA: registering ${JSON.stringify(registerBody)}`);
  const registerResponse = await fetch(registerUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(registerBody),
  });
  const registerJs = await registerResponse.json();

  if (registerResponse.status === 500) {
    Logger(
      `handleASPCA: error registering: ${JSON.stringify(registerJs)}`,
      true,
      sentryOptions
    );
  } else {
    Logger(
      `handleASPCA: create account response: ${JSON.stringify(registerJs)}`,
      true,
      sentryOptions
    );
  }

  // todo : do we need to capture the registration information?
  // e.g. create account response: {"userId":286096,"petOwnerId":0,"isVerified":false}
};

export const CFMemberCenterUrl = 'https://www.aspcapetinsurance.com/portal/';
