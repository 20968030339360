import { ApolloClient } from '@apollo/client';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  HomeScreenName,
  ProductChooserScreenName,
  RootStackParamList,
} from '../../screen-config';
import { UpdateUserStateOnServer } from './UserHelpers';

export const USER_STATE_CREATE_ACCOUNT_CONTINUE = 'CREATE_ACCOUNT_CONTINUE';
export const USER_STATE_PRODUCT_CHOOSER = 'PRODUCT_CHOOSER';
export const USER_STATE_PRODUCT_CHAT = 'PRODUCT_CHAT';
export const USER_STATE_SUGGESTED_COVERAGE = 'SUGGESTED_COVERAGE';
export const USER_STATE_PAYMENT_INFO = 'PAYMENT_INFO';
export const USER_STATE_COMPLETE = 'COMPLETE';
export const USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY =
  'ACCOUNT_DETAILS_BEFORE_PAY';

export const goHome = async (
  client: ApolloClient<object>,
  navigation: StackNavigationProp<RootStackParamList>,
  isGuest: boolean
): Promise<void> => {
  if (isGuest) {
    await UpdateUserStateOnServer(client, USER_STATE_PRODUCT_CHOOSER);
    navigation.navigate(ProductChooserScreenName);
  } else {
    await UpdateUserStateOnServer(client, USER_STATE_COMPLETE);
    navigation.navigate(HomeScreenName);
  }
};
