import styled from 'styled-components';
import { isFullWeb } from './DeviceHelper';
import { isMobileSafari } from './DeviceHelper.web';
import { Dimensions } from 'react-native';
import { WaffleBackground } from '../Constants/Style';

const getHeight = () =>
  isFullWeb()
    ? '100vh'
    : isMobileSafari()
    ? '100%'
    : `${Dimensions.get('window').height}px`;

const Container = styled.div`
  display: flex;
  background: ${WaffleBackground};
  height: ${getHeight()};
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;

export default Container;
