// AuthenticationService?  AuthHelper?

import AuthenticationService from './AuthenticationService';
import {
  CreateAccountConfirmScreenName,
  RootStackParamList,
} from '../../screen-config';
import { StackNavigationProp } from '@react-navigation/stack';
import { Logger } from './Logger';
import { GetCurrentUser_currentUser } from '../../../operation-result-types';
import { ApolloClient } from '@apollo/client';

interface AuthError extends Error {
  code: string | null;
}

function getLoginErrorMessage(error: AuthError): string {
  const { code, message } = error;

  if (code) {
    if (code === 'UserNotFoundException' || code === 'NotAuthorizedException') {
      return error.message;
    }
  }
  Logger(`response: ${code} ${message}`);
  return 'Unexpected error logging in';
}

// todo : hoist into AuthProvider in a reducer
export async function Login(
  navigation: StackNavigationProp<RootStackParamList>,
  client: ApolloClient<object>,
  email: string,
  password: string,
  setIsSignedIn: (
    token: string,
    user?: GetCurrentUser_currentUser,
    isGuest?: boolean
  ) => void
) {
  try {
    const creds = await AuthenticationService.LogIn(client, email, password);
    setIsSignedIn(creds.token, creds.user, false);
    return creds;
  } catch (e) {
    if (e.code === 'UserNotConfirmedException') {
      navigation.navigate(CreateAccountConfirmScreenName, {
        initialMessage: 'Please confirm your email first',
        email,
      });
    }
    const errorMessage = getLoginErrorMessage(e);

    Logger(`Login failure: ${errorMessage}`);

    throw new Error(errorMessage);
  }
}
