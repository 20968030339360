// Routines for dealing with users, state

// Retrieves the user details given a valid token
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import {
  GET_CURRENT_USER,
  GET_LOCAL_USER,
  GET_LOCAL_USER_ID,
  UPDATE_USER_STATE,
} from '../GraphQL/Waffle/Queries';
import { CognitoSignOut } from './CognitoAuth';
import { ApolloClient } from '@apollo/client';
import {
  GetCurrentUser,
  GetCurrentUser_currentUser,
  LocalUser_user,
} from '../../../operation-result-types';
import { USER_STATE_COMPLETE } from './NavigationService';
import { cachedTokenReactiveVar } from '../GraphQL/Waffle/ApolloClientBuilder';
import { getBuildNumber } from './FeatureFlags';
import { Platform } from 'react-native';

export const BuildVersionAndPlatform = () => {
  return {
    build_version: getBuildNumber(),
    platform: Platform.OS,
  };
};

export const CacheUser = (client: ApolloClient<object>, user: any) => {
  client.writeQuery({
    query: GET_LOCAL_USER_ID,
    data: {
      userId: user.id,
    },
  });
  client.writeQuery({
    query: GET_LOCAL_USER,
    data: {
      user: {
        ...user,
        __typename: 'User',
      },
    },
  });
};

export const CacheGuestToken = async (token: string) => {
  cachedTokenReactiveVar(token);
  await AsyncStorage.setItem('GUEST_TOKEN', token);
};

export const FetchGuestToken = async () => {
  return await AsyncStorage.getItem('GUEST_TOKEN');
};

export const RestoreGuestToken = async () => {
  const token = await AsyncStorage.getItem('GUEST_TOKEN');

  // todo : ensure it's valid!
  cachedTokenReactiveVar(token);

  return token;
};

export const ClearGuestToken = async () => {
  cachedTokenReactiveVar('');
  await AsyncStorage.removeItem('GUEST_TOKEN');
};

export const UpdateUserStateOnServer = async (
  client: ApolloClient<object>,
  state: any
) => {
  await client.mutate({
    mutation: UPDATE_USER_STATE,
    variables: {
      state,
    },
  });

  if (state === USER_STATE_COMPLETE) {
    await RefreshUserState(client);
  }
};

export const IsIncompleteUser = (isGuest: boolean, user: LocalUser_user) =>
  isGuest &&
  (!user?.firstName || !user?.lastName || !user?.address || !user?.city);

export const CompleteUserState = async (client: ApolloClient<object>) =>
  await UpdateUserStateOnServer(client, USER_STATE_COMPLETE);

export const RefreshUserState = async (
  client: ApolloClient<object>
): Promise<GetCurrentUser_currentUser | null> => {
  const {
    data: { currentUser },
  } = await client.query<GetCurrentUser>({
    query: GET_CURRENT_USER,
    fetchPolicy: 'network-only',
  });

  if (currentUser) {
    // todo : if currentUser is null, we need to clear state and throw user back
    //        to Login?
    client.writeQuery({
      query: GET_LOCAL_USER_ID,
      data: {
        userId: currentUser.id,
      },
    });

    client.writeQuery({
      query: GET_LOCAL_USER,
      data: {
        user: {
          ...currentUser,
          __typename: 'User',
        },
      },
    });

    return currentUser;
  }

  return null;
};

export const ClearUserState = async (client: ApolloClient<object>) => {
  await CognitoSignOut();
  cachedTokenReactiveVar('');
  await AsyncStorage.clear();
  await client.resetStore();
};

export const UserAge = (user: LocalUser_user) => {
  const birthDay = moment(user.dateOfBirth).format('YYYY-MM-DD');

  return moment().diff(birthDay, 'years', true);
};

export const UserName = (user: LocalUser_user) =>
  user ? `${user.firstName} ${user.lastName}` : 'unknown';

export const GetTokenFromAsync = async (): Promise<string> => {
  let token: string;

  try {
    const data = await Auth.currentSession();

    if (data?.isValid()) {
      token = data.getIdToken().getJwtToken();
    } else {
      token = null;
    }
  } catch (e) {
    // Logger(`DEBUG: GetTokenFromAsync: cognito?=${hasCognito} error retrieving token: ${JSON.stringify(e)}`);
    token = null;
  }

  if (!token) {
    token = await FetchGuestToken(); // Try retrieving guest token from local storage.
  }

  // Logger(`DEBUG: GetTokenFromAsync: cognito?=${hasCognito} token=${token}`)

  return token;
};

export interface UserState {
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  referralCode?: string;
  validatedAddress?: boolean;
  completedAddress?: any; // todo : fix
  rawAddress?: string;
}

export interface Address {
  houseNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}
