import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import invariant from 'fbjs/lib/invariant';

const initialURL = canUseDOM ? window.location.href : '';

// https://stackoverflow.com/questions/61198982/how-can-i-open-an-external-link-in-new-tab-in-react-native
const CustomLinking = {
  canOpenURL(): Promise<boolean> {
    return Promise.resolve(true);
  },

  getInitialURL(): Promise<string> {
    return Promise.resolve(initialURL);
  },

  openURL(url: string, target = '_self', options = undefined) {
    open(url, target, options);
  },

  openSelfWithPost(
    url: string,
    data: any,
    target?: string
  ): Promise<Record<string, unknown> | void> {
    const dataForm = document.createElement('form');

    // dataForm.style.display = "none";
    dataForm.method = 'POST';
    dataForm.action = url;
    if (target) {
      dataForm.target = target;
    }

    const keys = new Set(Object.keys(data));
    for (const key in data) {
      if (keys.has(key)) {
        const postData = document.createElement('input');
        postData.type = 'hidden';
        postData.name = key;
        postData.value = data[key];

        dataForm.appendChild(postData);
      }
    }

    document.body.appendChild(dataForm);

    dataForm.submit();
    return Promise.resolve();
  },

  openWithPost(
    url: string,
    data: any,
    target = '_self'
  ): Promise<Record<string, unknown> | void> {
    const dataForm = document.createElement('form');

    dataForm.style.display = 'none';
    dataForm.target = 'TargetWindow'; //Make sure the window name is same as this value
    dataForm.method = 'POST';
    dataForm.action = url;

    for (const key in data) {
      const postData = document.createElement('input');
      postData.type = 'hidden';
      postData.name = key;
      postData.value = data[key];

      dataForm.appendChild(postData);
    }

    document.body.appendChild(dataForm);

    const postWindow = window.open(
      '',
      target,
      'status=0,title=0,height=600,width=800,scrollbars=1'
    );

    if (postWindow) {
      dataForm.submit();
      return Promise.resolve();
    } else {
      return Promise.reject('You must allow popups for this map to work.');
    }
  },

  _validateURL(url: string) {
    invariant(
      typeof url === 'string',
      'Invalid URL: should be a string. Was: ' + url
    );
    invariant(url, 'Invalid URL: cannot be empty');
  },
};

const open = (url, target, options) => {
  if (canUseDOM) {
    window.open(
      url,
      target, // <- This is what makes it open in a new window.
      options
    );
  }
};

export default CustomLinking;
