import { valToLabel } from './MiscFunctions';
import { PolicyCoverageCoverage } from '../../component-config';
import { COVERAGE_SELECTION_TYPE_CHOICE } from './PolicyHelper';

const UNIT_DOLLARS = '3';
const UNIT_PERCENTAGE = '1';

export const nextValue = (v: string, step: number, maximum: number) =>
  Math.min(parseInt(v) + step, maximum);

export const previousValue = (v: string, step: number, minimum: number) =>
  Math.max(parseInt(v) - step, minimum);

export const nextChoice = (v: string, choices: string) => {
  const l = choices.split(',').map((el) => el.split(':'));
  const idx = l.findIndex((el) => el[0] === v.toString());

  // Logger(`next: ${v} choices: ${choices} idx: ${idx}`)

  return l[Math.min(l.length - 1, idx + 1)][0];
};

export const previousChoice = (v: string, choices: string) => {
  const l = choices.split(',').map((el) => el.split(':'));
  const idx = l.findIndex((el) => el[0] === v.toString());

  return l[Math.max(0, idx - 1)][0];
};

export const countWithUnit = (value: string, unit: string, choices: string) => {
  switch (unit) {
    case '%':
    case 'PERCENTAGE': // deprecated units; switched to the numeric code
    case UNIT_PERCENTAGE:
      return value + '%';
    case '$':
    case 'DOLLARS': // deprecated units; switched to the numeric code
    case UNIT_DOLLARS:
      // if "value" isn't a number, toLocaleString() doesn't do what we want
      // it to (which is commify), so we parseInt() the string first.  (Which
      // also works if "value" is already a number!?)
      return '$' + parseInt(value).toLocaleString();
    default:
      return valToLabel(value, choices);
  }
};

export const incrementValue = (
  value: string,
  coverage: PolicyCoverageCoverage
) => {
  const { selectionType, choices, step, maximumCoverage } = coverage;

  return selectionType === COVERAGE_SELECTION_TYPE_CHOICE
    ? nextChoice(value, choices)
    : nextValue(value, step, maximumCoverage).toString();
};

export const decrementValue = (
  value: string,
  coverage: PolicyCoverageCoverage
) => {
  const { selectionType, choices, step, minimumCoverage } = coverage;

  return selectionType === COVERAGE_SELECTION_TYPE_CHOICE
    ? previousChoice(value, choices)
    : previousValue(value, step, minimumCoverage).toString();
};
