import { Platform } from 'react-native';
import CustomLinking from './CustomLinking';
import { useNavigation } from '@react-navigation/native';
import {
  ArchMemberCenterScreenName,
  HomeScreenName,
} from '../../screen-config';

export const ArchMemberCenterUrl =
  'https://roamrightclaims.archinsurancesolutions.com/';

export const GoMemberCenter = async () => {
  const navigation = useNavigation();

  if (Platform.OS === 'web') {
    navigation.navigate(HomeScreenName);
    await CustomLinking.openURL(ArchMemberCenterUrl, '_blank');
    // window.open(ArchMemberCenterUrl, '_blank');
  } else {
    navigation.navigate(ArchMemberCenterScreenName);
  }
};
