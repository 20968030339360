// putting this in a separate file so we can eventually instrument logging
// back to the server...

import moment from 'moment';
// import DeviceInfo from 'react-native-device-info/src/index';
// import Sentry from '../../App/Sentry';
// import { Alert } from 'react-native';

const PRINT_DEBUG = true;
const PREFIX = ''; // ` ${ DeviceInfo.getDeviceNameSync() }`;

export async function LoggerAsync(s: any) {
  console.log(s);
  // Alert.alert(s);
}

// todo : add config debugging?
export function Logger(s: any, sentryCrumb = false, sentryCrumbOptions = {}) {
  const message = `${moment().format()} WaffleApp${PREFIX}: ${s}`;
  console.log(message);

  // if (sentryCrumb) {
  //   Sentry.addBreadcrumb({
  //     ...sentryCrumbOptions,
  //     message,
  //   });
  // }
}

export function LoggerCrumb(
  s: string,
  category: string,
  sentryCrumbOptions = {}
) {
  Logger(s, true, {
    // level: Sentry.Severity.Info,
    category,
    ...sentryCrumbOptions,
  });
}

// export function LoggerObject(
//   s: any,
//   obj: any,
//   sentryCrumb = false,
//   sentryCrumbOptions = {}
// ) {
//   Logger(
//     `${s} ${JSON.stringify(obj, null, 2)}`,
//     sentryCrumb,
//     sentryCrumbOptions
//   );
// }

export function LoggerDebug(
  s: any,
  sentryCrumb = false,
  sentryCrumbOptions = {}
) {
  if (PRINT_DEBUG) {
    Logger(s, sentryCrumb, sentryCrumbOptions);
  }
}
