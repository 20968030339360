import GetGraphqlRoot from './GetGraphqlRoot';
import { Platform } from 'react-native';
import CustomLinking from './CustomLinking';
import { NavigationGoHomeButton } from '../Components/SimpleComponents';
import { HomeScreenName, WebScreenName } from '../../screen-config';

export const ShowInvoices = async (policies, token, navigation) => {
  const uri = `${GetGraphqlRoot().media}/policies/customerBilling`;
  const title = 'Invoices';
  const headers = { Authorization: `Bearer ${token}` };
  const method = 'POST';
  const onMessage = () => navigation.navigate(HomeScreenName);

  if (Platform.OS === 'web') {
    const response = await fetch(`${uri}Web`, {
      method,
      headers,
    });

    const newUri = await response.text();

    await CustomLinking.openURL(newUri, '_self');
  } else {
    await navigation.navigate(WebScreenName, {
      uri,
      title,
      headers,
      method,
      onMessage,
      headerRight: NavigationGoHomeButton,
    });
  }
};
