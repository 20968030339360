// Authentication-related methods.  Will sign up, sign in, and manage
// user state.  Will also handle navigation transitions.

import { Auth } from 'aws-amplify';

import { CognitoSignUp, RestoreCognitoUserState } from './CognitoAuth';
import { Logger } from './Logger';
import {
  BuildVersionAndPlatform,
  CacheGuestToken,
  CacheUser,
  ClearGuestToken,
  ClearUserState,
  RestoreGuestToken,
} from './UserHelpers';
import {
  CREATE_GUEST_USER,
  CREATE_USER_DETAIL,
  DRAFT_POLICIES,
  GET_CURRENT_USER,
  REFRESH_GUEST_USER,
} from '../GraphQL/Waffle/Queries';
import { setTelemetryUser } from './TelemetryService';
import {
  CreateGuestUser,
  CreateGuestUserVariables,
  CreateUserDetail,
  CreateUserDetailVariables,
  GetCurrentUser,
  GetDraftUserPolicies,
  RefreshGuestUser,
  RefreshGuestUserVariables,
} from '../../../operation-result-types';
import { ApolloClient } from '@apollo/client';
import { USER_STATE_SUGGESTED_COVERAGE } from './NavigationService';
import {
  HasCyberPolicy,
  HasLifePolicy,
  HasPetPolicy,
  HasRentersPolicy,
  IsLifePolicy,
} from './PolicyHelper';
import {
  CyberQuoteScreenName,
  LifeApplicationScreenName,
  LifeOfferScreenName,
  LifeQuoteQuestionsScreenName,
  LifeQuoteScreenName,
  PetQuoteScreenName,
  RentersQuoteScreenName,
  RootStackParamList,
  SuggestedCoverageScreenName,
} from '../../screen-config';
import {
  cachedTokenReactiveVar,
  cachedTrackingInfo,
} from '../GraphQL/Waffle/ApolloClientBuilder';
import { UpdateUserTraitsProps } from '../../lib/user-tracking/UpdateUserTraitsProps';

interface UserDetails {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  referralCode?: string;
}

const Initialize = () => {
  Logger('Initializing authentication');
  Auth.configure({
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  });
};

const LogIn = async (
  client: ApolloClient<object>,
  email: string,
  password: string
) => {
  await Auth.signIn(email, password);
  const { user, token } = await RestoreCognitoUserState(client);

  if (!token) {
    // todo : Need to set error message?
    Logger(
      `error restoring cognito user state.  Token empty!  User=${JSON.stringify(
        user
      )}`
    );
    return;
  }

  await CacheUser(client, user);
  return { token, user };
};

const SignUp = async (
  client: ApolloClient<object>,
  email: string,
  password: string,
  attributes?: Record<string, unknown>
) => {
  try {
    return await CognitoSignUp(email, password, attributes);
  } catch (e) {
    throw new Error(e.message);
  }
};

const ConfirmSignUp = async (email: string, code: string) => {
  try {
    return await Auth.confirmSignUp(email, code);
  } catch (e) {
    Logger(`error confirming sign up: ${JSON.stringify(e)}`);
    throw new Error(e.message);
  }
};

const _containsClickId = (userTraits: UpdateUserTraitsProps) => {
  return (
    userTraits.cclid ||
    userTraits.gclid ||
    userTraits.mclid ||
    userTraits.agentName
  );
};

const _getClickId = (userTraits: UpdateUserTraitsProps) => {
  if (userTraits.cclid) return userTraits.cclid;
  if (userTraits.mclid) return userTraits.mclid;
  if (userTraits.gclid) return userTraits.gclid;
  if (userTraits.agentName) return userTraits.agentName;
};

const SignInGuestUser = async (
  client: ApolloClient<object>,
  zip: string,
  userTraits: UpdateUserTraitsProps,
  productId?: string
) => {
  const trackingInfo: {
    clickId?: string;
    utmSource?: string;
    utmCampaign?: string;
  } = {};

  if (userTraits && _containsClickId(userTraits)) {
    trackingInfo.clickId = _getClickId(userTraits);
    trackingInfo.utmSource = userTraits.utmSource;
    trackingInfo.utmCampaign = userTraits.utmCampaign;

    cachedTrackingInfo(trackingInfo);
  }
  const {
    data: { createGuestUser },
  } = await client.mutate<CreateGuestUser, CreateGuestUserVariables>({
    mutation: CREATE_GUEST_USER,
    variables: {
      input: {
        zip,
        productId,
        ...BuildVersionAndPlatform(),
        ...trackingInfo,
      },
    },
  });

  Logger(`getStarted response: ${JSON.stringify(createGuestUser)}`);

  const { token, message, user } = createGuestUser;

  if (user && token) {
    // Create guest user can fail, so do not cache if we have...
    await CacheUser(client, user);
    await CacheGuestToken(token); // store this?
  }

  return { token, message, user };
};

const ForgotPassword = async (email: string) => {
  try {
    const response = await Auth.forgotPassword(email);

    Logger(`response from ForgotPassword: ${JSON.stringify(response)}`);
  } catch (e) {
    Logger(`error with ForgotPassword: ${JSON.stringify(e.message)}`);
    throw new Error(e.message);
  }
};

const ResetPassword = async (
  email: string,
  code: string,
  newPassword: string
) => {
  try {
    const response = await Auth.forgotPasswordSubmit(email, code, newPassword);
    Logger(`response from ForgotPasswordSubmit: ${JSON.stringify(response)}`);
    return response;
  } catch (e) {
    Logger(`error with ForgotPasswordSubmit: ${JSON.stringify(e.message)}`);
    throw new Error(e.message);
  }
};

const SignOut = async (client: ApolloClient<object>) => {
  // Switch from Logged in screens to welcome, then clear user state.  Otherwise, things blow up.
  await ClearUserState(client);
};

const restoreGuestUser = async (client: ApolloClient<object>) => {
  try {
    let token = await RestoreGuestToken();

    if (token) {
      const {
        data: { refreshGuestUser },
      } = await client.mutate<RefreshGuestUser, RefreshGuestUserVariables>({
        mutation: REFRESH_GUEST_USER,
        fetchPolicy: 'network-only',
        variables: { input: { token } },
      });

      // If refresh fails, it will return an empty string.  So this will clear it out.
      token = refreshGuestUser?.token;
      cachedTokenReactiveVar(token);
    }

    if (!token) {
      Logger(`guest token expired or did not exist.  clearing.`);

      await ClearGuestToken();

      return {};
    }

    if (token) {
      const {
        data: { currentUser },
      } = await client.query<GetCurrentUser>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      });

      // If we don't get back a valid user, we need to bail.  We should verify
      // that we didn't error or timeout from the backend, but if we can't restore
      // the user, we should assume there's a problem with the token, and clear
      // out the user.
      if (!currentUser || !currentUser.id) {
        Logger(
          `RestoreGuestUser: token ${JSON.stringify(
            token
          )} suspect user: ${JSON.stringify(currentUser)}  Clearing login`
        );

        return {};
      }

      await CacheUser(client, currentUser);

      return { token, user: currentUser, userId: currentUser.id }; // this comes from database, not AWS
    }
  } catch (e) {
    // todo : this could throw a graphQL error...
    Logger(`error restoring user: ${JSON.stringify(e)} code=${e.code}`);
  }

  return {};
};

const RestoreUserAndStart = async (client: ApolloClient<object>) => {
  let { token, user } = await RestoreCognitoUserState(client);
  let isGuest = false;
  let initialScreen: keyof RootStackParamList = null;

  if (!token) {
    // note to developers: comment out this block to disable guest user restoration.
    ({ token, user } = await restoreGuestUser(client));
    Logger(`restored guest user: token=${token} user=${JSON.stringify(user)}`);

    if (token) {
      isGuest = true;
    }
  }

  // This will switch to the App screen or Auth screen and this loading
  // screen will be unmounted and thrown away.
  if (token && user?.userState) {
    if (user?.id) {
      setTelemetryUser(user.id, user.email);
    }

    if (
      user?.userState === USER_STATE_SUGGESTED_COVERAGE ||
      user?.userState === 'CHAT_COMPLETE'
    ) {
      const {
        data: { draftUserPolicies },
      } = await client.query<GetDraftUserPolicies>({
        query: DRAFT_POLICIES,
        fetchPolicy: 'network-only',
      });

      if (HasCyberPolicy(draftUserPolicies)) {
        initialScreen = CyberQuoteScreenName;
      } else if (HasPetPolicy(draftUserPolicies)) {
        initialScreen = PetQuoteScreenName;
      } else if (HasRentersPolicy(draftUserPolicies)) {
        initialScreen = RentersQuoteScreenName;
      } else if (HasLifePolicy(draftUserPolicies)) {
        const draftLifePolicy = draftUserPolicies.find(IsLifePolicy);
        if (draftLifePolicy.externalPolicy.OfferId) {
          initialScreen = LifeOfferScreenName;
        } else if (draftLifePolicy.externalPolicy.ExternalApplicationId) {
          initialScreen = LifeApplicationScreenName;
        } else if (draftLifePolicy.externalPolicy.ExternalQuoteId) {
          initialScreen = LifeQuoteScreenName;
        } else {
          initialScreen = LifeQuoteQuestionsScreenName;
        }
      } else {
        initialScreen = SuggestedCoverageScreenName;
      }

      Logger(`initialScreen: ${initialScreen}`);
    }
  }

  return { token, user, isGuest, initialScreen };
};

const UpdateUserDetails = async (
  client: ApolloClient<object>,
  userId: string,
  state: UserDetails
) => {
  // This whole process works, but is rather distasteful.  Need to fix!

  Logger(`CreateAccountScreen: saving user ${JSON.stringify(state)}`);
  const {
    data: {
      createUserDetail: { user },
    },
  } = await client.mutate<CreateUserDetail, CreateUserDetailVariables>({
    mutation: CREATE_USER_DETAIL,
    variables: {
      id: userId,
      firstName: state.firstName.trim(),
      lastName: state.lastName.trim(),
      dateOfBirth: state.dateOfBirth,
      address: state.address.trim(),
      address2: state.address2?.trim(),
      city: state.city.trim(),
      state: state.state.trim(),
      zip: state.zip.trim(),
      phone: state.phone.trim(),
      referralCode: state.referralCode,
    },
  });

  await CacheUser(client, user);

  // await RestoreUserState(client);  // Did I mention this logic really should get fixed?

  return user;
};

const ChangePassword = async (
  existingPassword: string,
  newPassword: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, existingPassword, newPassword);
};

export default {
  LogIn,
  SignUp,
  ConfirmSignUp,
  SignOut,
  RestoreUserAndStart,
  UpdateUserDetails,
  ChangePassword,
  Initialize,
  ForgotPassword,
  ResetPassword,
  SignInGuestUser,
};
